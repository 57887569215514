<div class="maintenance-data">
    <div class="heading blue">
        <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''"><i
                class="fa fa-th" aria-hidden="true"></i></span>
        <span routerLink="/maintenance"
              class="clickable">{{ 'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate }}</span>
        <span routerLink="/maintenance/suggestion"><i
                class="fa fa-caret-right"></i>{{ 'MAINTENANCE.SUGGESTION' | translate }}</span>
    </div>
</div>


<div class="panel-content center-content app-suggestion">
    <div class="maintenance-wizard">
        <h1>
            <span>{{ 'MAINTENANCE.SUGGESTION_TITLE' | translate }}</span>
        </h1>

        <div *ngIf="gearError || maintenanceService.cooGroupsError">
            <div class="alert alert-info w-100 text-center" role="alert">
                <i class="fa fa-info-circle mr-2"></i>
                <span>{{ 'MAINTENANCE.NO_PROPOSAL_MAINTENANCE' | translate }}</span>
            </div>
            <div class="text-right w-100">
                <button class="base-btn" routerLink="/maintenance/plan">
                    <span>{{ 'MAINTENANCE.TO_MAINTENANCE' | translate }}</span>
                </button>
            </div>
        </div>

        <app-wizard *ngIf="!(gearError || maintenanceService.cooGroupsError)">
            <app-wizard-step
                    [title]="(maintenanceService.getMileage()> 999 ? maintenanceService.getMileage() + ' ' + (userService.getMileageUnit() | translate) : 'MAINTENANCE.MILEAGE' | translate)"
                    [isValid]="maintenanceService.getMileage() > 999"
                    (onNext)="onStep1Next($event)"
                    [doNext]="next">
                <div class="row justify-content-center">
                    <div class="col-12 question">
                        <h1>(1/5)<br/>{{ 'MAINTENANCE.QUESTION.1' |  translate }}</h1>
                    </div>
                    <div class="col-xl-4 col-md-6 text-center">
                        <app-material-form-input [autofocus]="true" (text)="maintenanceService.setMileage($event)"
                                                 [text]="maintenanceService.getMileage()"
                                                 (textChange)="kilometerChange(); maintenanceService.setMileage($event)"
                                                 (keydown)="keyDownFunction($event, 1)"
                                                 [label]=""></app-material-form-input>
                        {{ userService.getMileageUnit() | translate }}
                    </div>
                </div>
            </app-wizard-step>

            <app-wizard-step
                    [title]="(maintenanceService.getYear() ? ('MAINTENANCE.DATE' | translate) + ': ' + (maintenanceService.getYear() | date:'.M.yyyy' ): 'MAINTENANCE.DATE' | translate)"
                    [isValid]="dateForm.controls.localDate.valid"
                    [doNext]="next"
                    (onNext)="onStep2Next($event)">
                <div class="row justify-content-center">
                    <div class="col-12 question">
                        <h1>(2/5)<br/>{{ 'MAINTENANCE.QUESTION.2' |  translate }}</h1>
                    </div>
                    <div class="col-xl-4 col-md-6 text-center">
                        <form [formGroup]="dateForm" class="form-inline">
                            <div class="form-group">
                                <div class="input-group">
                                    <input class="form-control" autofocus placeholder="dd.mm.yyyy" name="dp"
                                           formControlName="localDate" (change)="maintenanceService.setRegistrationDate(dateForm.get('localDate'))"
                                           (ngModelChange)="setDate($event)" (keydown.enter)="nextAfterDate()"
                                           [minDate]="minDate" ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="dateForm.controls.localDate.invalid && (dateForm.controls.localDate.dirty || dateForm.controls.localDate.touched)"
                                 class="text-danger text-center w-100">
                                <div *ngIf="dateForm.controls.localDate.errors.required">
                                    {{ 'GENERAL.DATE_VALIDATION.REQUIRED' | translate }}
                                </div>
                                <div *ngIf="dateForm.controls.localDate.errors.invalidFormat">
                                    {{ 'GENERAL.DATE_VALIDATION.FORMAT' | translate }}
                                </div>
                                <div *ngIf="dateForm.controls.localDate.errors.minDate">
                                    {{
                                        'GENERAL.DATE_VALIDATION.MIN_DATE' | translate:{
                                            minDay: minDate.day,
                                            minMonth: minDate.month,
                                            minYear: minDate.year
                                        }
                                    }}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </app-wizard-step>

            <app-wizard-step
                    [title]="(maintenanceService.selectedGear ? ('MAINTENANCE.GEAR.' +  maintenanceService.selectedGear | translate) : 'MAINTENANCE.GEARS' | translate)"
                    [isValid]="maintenanceService.selectedGear"
                    (onNext)="onStep3Next($event)"
                    [doNext]="next">
                <div class="row justify-content-center">
                    <div class="col-12 question">
                        <h1>(3/5)<br/>{{ 'MAINTENANCE.QUESTION.3'| translate }}</h1>
                    </div>
                    <div class="col-12" style="padding-top: 22px;">

                        <ul class="item-list" [@detailAnim]="maintenanceService.gearBox">
                            <ng-container *ngFor="let gear of maintenanceService.gearBox">
                                <li [ngClass]="{'selected' : maintenanceService.selectedGear === gear}"
                                    (click)="setGear(gear)"
                                    class="clickable"
                                    [@selected]="maintenanceService.selectedGear === gear ? 'move': ''">
                                            <span>
                                                <i class="fa "
                                                   [ngClass]="{'fa-check' : maintenanceService.selectedGear === gear, 'fa-file-text-o' : maintenanceService.selectedGear != gear}"></i>
                                                <span>{{ 'MAINTENANCE.GEAR.' + gear | translate }}</span>
                                            </span>
                                </li>
                            </ng-container>
                        </ul>


                        <!--<div class="" *ngFor="let gear of maintenanceService.gearBox">-->
                        <!--<app-checkbox [type]="'radio'" (onChange)="setGear(gear)"-->
                        <!--[isChecked]="maintenanceService.selectedGear === gear"-->
                        <!--[labelText]="'MAINTENANCE.GEAR.' +  gear | translate"></app-checkbox>-->
                        <!--</div>-->
                    </div>
                </div>
            </app-wizard-step>

            <app-wizard-step
                    [title]="(maintenanceService.activeCoo ? maintenanceService.activeCoo.qualColText : 'MAINTENANCE.GEARS' | translate)"
                    [isValid]="maintenanceService.activeCoo"
                    (onNext)="onStep4Next($event)"
                    [doNext]="next">
                <div class="row justify-content-center">

                    <div class="col-12 question">
                        <h1>(4/5)<br/>{{ 'MAINTENANCE.QUESTION.4' |  translate }}</h1>
                    </div>
                    <div class="col-12" style="padding-top: 22px;">

                        <ul class="item-list" [@detailAnim]="maintenanceService?.cooGroups">
                            <ng-container *ngFor="let coo of maintenanceService?.cooGroups">
                                <li [ngClass]="{'selected' : maintenanceService.activeCoo === coo}"
                                    (click)="toggleCoo(coo, $event)"
                                    class="clickable"
                                    [@selected]="maintenanceService.activeCoo === coo ? 'move': ''">
                                            <span>
                                                <i class="fa "
                                                   [ngClass]="{'fa-check' : maintenanceService.activeCoo === coo, 'fa-file-text-o' : maintenanceService.activeCoo != coo}"></i>
                                                <span>{{ coo.qualColText }}</span>
                                            </span>
                                </li>
                            </ng-container>
                        </ul>


                        <!--<div class="" *ngFor="let coo of maintenanceService?.cooGroups">-->
                        <!--<app-checkbox [isChecked]="maintenanceService.activeCoo === coo"-->
                        <!--(onChange)="toggleCoo(coo, $event)" [labelText]="coo.qualColText"></app-checkbox>-->
                        <!--</div>-->
                    </div>
                </div>
            </app-wizard-step>

            <app-wizard-step [title]="'MAINTENANCE.MAINTENANCE' | translate"
                             (onComplete)="onComplete($event)">
                <div [ngSwitch]="isCompleted">
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-12 question">
                                <h1>(5/5)<br/>{{ 'MAINTENANCE.QUESTION.5' |  translate }}</h1>
                            </div>
                            <div class="col-12 text-center">
                                <h2><span>{{ 'MAINTENANCE.LAST_SERVICE' | translate }}: </span>
                                </h2>
                                <small class="text-secondary" *ngIf="maintenanceService.serviceProposal?.lastKm >0">
                                    {{ 'MAINTENANCE.DUE_SINCE_MILEAGE' | translate }}
                                    : {{ maintenanceService.serviceProposal.lastKm }}
                                    <span tooltip tooltipPosition="align-center"
                                          tooltipContent="{{'MAINTENANCE.TOOLTIP_MILEAGE_INFO' | translate}}">
                                         <i class="fa fa-info-circle"></i>
                                    </span>
                                </small>
                            </div>
                            <div class="col-12 text-center" style="padding-top: 22px;"
                                 *ngIf="maintenanceService.serviceProposal?.lastServiceMainWorks?.length > 0">
                                <button *ngFor="let proposal of maintenanceService.serviceProposal?.lastServiceMainWorks; let i = index"
                                        class="base-btn no-top-margin" (click)="setSuggestion(proposal.workId, false)"
                                        [disabled]="maintenanceService.maintenanceLoading"
                                        [ngClass]="{'success': maintenanceService?.maintenanceSteps?.itemMpId == maintenanceService.serviceProposal?.lastServiceMainWorks[i]?.itemMpId}">
                                    <i *ngIf="maintenanceService.maintenanceLoading === 2" class="fa fa fa-spinner"
                                       [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"> </i>
                                    <i *ngIf="maintenanceService?.maintenanceSteps?.itemMpId == maintenanceService.serviceProposal?.lastServiceMainWorks[i]?.itemMpId"
                                       class="fa fa-check"> </i>
                                    <span> {{ maintenanceService.serviceProposal?.lastServiceMainWorks[i]?.qualColText }} {{ maintenanceService.serviceProposal?.lastServiceMainWorks[i]?.itemMpText }}</span>
                                </button>
                                <span *ngIf="maintenanceService.serviceProposal?.lastServiceMainWorks?.length === 0"><i
                                        class="fa fa-times"></i>{{ 'MAINTENANCE.NOT_AVAIABLE' | translate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h2><span>{{ 'MAINTENANCE.NEXT_SERVICE' | translate }} : </span>
                                </h2>
                                <small class="text-secondary"
                                       *ngIf=" maintenanceService.serviceProposal?.nextKm > 0">
                                    {{ 'MAINTENANCE.DUE_FROM_MILEAGE' | translate }}
                                    : {{ maintenanceService.serviceProposal.nextKm }}
                                    <span tooltip tooltipPosition="align-center"
                                          tooltipContent="{{'MAINTENANCE.TOOLTIP_MILEAGE_INFO' | translate}}">
                                         <i class="fa fa-info-circle"></i>
                                    </span>
                                </small>
                            </div>
                            <div class="col-12 text-center" style="padding-top: 22px;"
                                 *ngIf="maintenanceService.serviceProposal?.nextServiceMainWorks?.length > 0">
                                <button *ngFor="let proposal of maintenanceService.serviceProposal?.nextServiceMainWorks; let i = index"
                                        class="base-btn no-top-margin" (click)="setSuggestion(proposal.workId, true)"
                                        [disabled]="maintenanceService.maintenanceLoading"
                                        [ngClass]="{'success': maintenanceService?.maintenanceSteps?.itemMpId == maintenanceService.serviceProposal?.nextServiceMainWorks[i]?.itemMpId}">
                                    <i *ngIf="maintenanceService.maintenanceLoading === 1" class="fa fa fa-spinner"
                                       [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"> </i>
                                    <i *ngIf="maintenanceService?.maintenanceSteps?.itemMpId == maintenanceService.serviceProposal?.nextServiceMainWorks[i]?.itemMpId"
                                       class="fa fa-check"> </i>
                                    <span> {{ maintenanceService.serviceProposal?.nextServiceMainWorks[i]?.qualColText }} {{ maintenanceService.serviceProposal?.nextServiceMainWorks[i]?.itemMpText }}</span>
                                </button>
                                <span *ngIf="maintenanceService.serviceProposal?.nextServiceMainWorks?.length === 0">
                                    <i class="fa fa-times"></i>{{ 'MAINTENANCE.NOT_AVAIABLE' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-wizard-step>
        </app-wizard>
    </div>
</div>
