import {Component, EventEmitter, OnInit} from '@angular/core';
import {CarService} from '../../../shared/services/car/car.service';
import {MaintenanceService} from '../../services/maintenance.service';
import {Router} from '@angular/router';
import {ICooGroups} from '../../interfaces/cooGroups';
import {detailAnim, itemAnimation, itemSelected} from '../../../shared/animations/general.animation';
import {CustomerDataService} from '../../../shared/services/customerData.service';
import {HelperService} from '../../../shared/services/helper.service';
import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Dateformat} from '../../../shared/dateformat';
import {UserService} from '../../../shared/services/user/user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CartService} from "../../../cart/services/cart.service";

@Component({
    selector: 'app-maintenance-wizard',
    templateUrl: './maintenance-wizard.component.html',
    animations: [detailAnim, itemAnimation, itemSelected],
    providers: [{provide: NgbDateParserFormatter, useClass: Dateformat}]
})
export class MaintenanceWizardComponent implements OnInit {
    public dateForm: FormGroup;
    public next: EventEmitter<any> = new EventEmitter<any>();
    public isCompleted: boolean;
    public gearError = false;
    public localDate: NgbDateStruct;
    public minDate: NgbDateStruct;

    constructor(public maintenanceService: MaintenanceService,
                public carService: CarService,
                private router: Router,
                public customerDataService: CustomerDataService,
                public helperService: HelperService,
                public userService: UserService,
                private cartService: CartService,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.maintenanceService.lastState = '/maintenance/suggestion';
        this.maintenanceService.loadSuggestion();
        this.gearError = !(this.maintenanceService.gearBox && this.maintenanceService.gearBox.length > 0);
        this.maintenanceService.localDataHolder = this.helperService.recursiveDeepCopy(this.customerDataService.getDataHolder());

        const fromDate = this.carService.selectedCar.constructionFrom.split('.');
        this.minDate = {year: parseInt(fromDate[1]), month: parseInt(fromDate[0]), day: 1};
        this.dateForm = this.fb.group({
            localDate: ['', [Validators.required, this.dateValidator.bind(this)]]
        });


        if (this.maintenanceService.getRegistrationDate()) {
            const dateParts = this.maintenanceService.getRegistrationDate().toString().split('-');
            const ngbDate: NgbDateStruct = {
                year: parseInt(dateParts[0]),
                month: parseInt(dateParts[1]),
                day: parseInt(dateParts[2])
            };
            this.dateForm.get('localDate')?.setValue(ngbDate);
        }
    }


    public dateValidator(control: FormControl): { [key: string]: any } | null {
        const value = control.value;
        let date: NgbDateStruct;
        if (typeof value === 'string' && value.includes('.')) {
            const dateParts = value.split('.');
            if (dateParts.length !== 3) {
                return {'invalidFormat': true};
            }
            date = {year: parseInt(dateParts[2]), month: parseInt(dateParts[1]), day: parseInt(dateParts[0])};
        } else {
            date = value;
        }

        if (!date || !date.year || !date.month || !date.day || isNaN(date.year) || isNaN(date.month) || isNaN(date.day)) {
            return {'invalidFormat': true};
        }

        if (date.year < this.minDate.year ||
            (date.year === this.minDate.year && date.month < this.minDate.month) ||
            (date.year === this.minDate.year && date.month === this.minDate.month && date.day < this.minDate.day)) {
            return {'minDate': true};
        }
        return null;
    }


    public onStep1Next(e: any) {
        this.customerDataService.setDataHolder(this.maintenanceService.localDataHolder);
        if (this.customerDataService.getYear()) {
            const str = this.customerDataService.getYear().toString();
            const arr = str.split('-');
            this.localDate = new NgbDate(parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]));
            this.setDate(this.localDate);
        } else if (this.carService.registrationDate) {
            const str = this.carService.registrationDate;
            const arr = str.split('.');
            this.localDate = new NgbDate(parseInt(arr[2]), parseInt(arr[1]), parseInt(arr[0]));
            this.setDate(this.localDate);
        }
    }

    public keyDownFunction(e: any, step: number) {
        if (e.keyCode === 13 && step === 1) {
            this.next.emit(true);
        }

    }

    public onStep2Next(e: any) {
        this.customerDataService.setDataHolder(this.maintenanceService.localDataHolder);
    }

    public onStep3Next(e: any) {
    }

    public onStep4Next(e: any) {
    }

    public onComplete(e: any) {
        void this.router.navigate(['/maintenance/plan']);
    }

    public kilometerChange() {
        this.maintenanceService.cooGroups = [];
        this.maintenanceService.activeCoo = undefined;
        this.checkInfo();
    }

    public setDate(date: any) {
        if (date && typeof date !== 'string' && date.year && date.month && date.day) {
            this.maintenanceService.cooGroups = [];
            this.maintenanceService.activeCoo = undefined;
            const newDate = new Date();
            newDate.setDate(date.day);
            newDate.setMonth(date.month - 1);
            newDate.setFullYear(date.year);
            this.maintenanceService.setYear(newDate);
            this.checkInfo();
        }
    }

    public nextAfterDate() {
        if (this.maintenanceService.getYear()) {
            this.onStep2Next(null);
            this.next.emit(true);
        }
    }

    public setGear(newGear: any) {
        this.maintenanceService.selectedGear = newGear;
        this.maintenanceService.cooGroups = [];
        this.maintenanceService.activeCoo = undefined;
        this.checkInfo();
        this.onStep3Next(null);
        this.next.emit(true);
    }

    private checkInfo() {
        if (this.maintenanceService.selectedGear !== '' && this.maintenanceService.getMileage() > 999 && this.maintenanceService.getYear()) {
            this.maintenanceService.getCooGroups(this.maintenanceService.selectedGear, this.maintenanceService.calcDate(), 0);
        }
    }

    public setSuggestion(mainWorkId: number, next: boolean) {
        this.maintenanceService.setServiceProposal(0, this.maintenanceService.activeCoo.qualColId, this.maintenanceService.getMileage(), this.maintenanceService.calcDate(), 0, next, this.carService.getCarProducerName(), mainWorkId);
    }

    public toggleCoo(coo: ICooGroups, event: any) {
        if (this.maintenanceService.activeCoo !== coo && event) {
            this.maintenanceService.activeCoo = coo;
            this.maintenanceService.getServiceProposal(0, this.maintenanceService.activeCoo.qualColId, this.maintenanceService.getMileage(), this.maintenanceService.calcDate(), 0);
            this.onStep4Next(null);
            this.next.emit(true);
        } else if (this.maintenanceService.activeCoo === coo && !event) {
            this.maintenanceService.activeCoo = undefined;
        }
    }
}
